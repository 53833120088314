.hiw_box {
  box-shadow: 2px 4px 8.4px -7px rgba(0, 0, 0, .25);
  background: #fff;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 30px;
  border: 1px solid #0071BC;
  height: 120px;
  text-align: center;
}
.hiw_box i {
	width: 116px;
	height: 116px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #0071bc;
	border-radius: 50%;
	margin: auto;
	margin-top: -60px;
}
.hiw_box span {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #F7A5F9;
}
.hiw_box div {
  font-size: 16px;
  color: #333;
  text-align: center;
}
