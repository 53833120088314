.h2 {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
  }
  .questionsLabel {
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
  }
 
  