.faq_main {
	width: 100%;
	position: relative;
	margin: 40px 0px;
	overflow: hidden;
}

.faq_inner {
	width: 100%;
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 10px;
	padding-bottom: 10px;
	flex-direction: row-reverse;
}

.faq_left {
	width: 60%;
	padding: 40px 50px 40px 0px;
	position: relative;
	z-index: 2;
	text-align: center;
}

.faq_left h2 {
	font-family: "Montserrat", sans-serif;
	font-weight: 700;
	color: #0071bc;
	font-size: 54px;
	padding-bottom: 20px;
	margin: 0px;
	line-height: 1em;
}

.faq_left h3 {
	font-weight: 700;
	color: #002f34;
	font-size: 28px;
	line-height: 1em;
	padding-bottom: 12px;
	margin: 0px;
}

.faq_left p {
	font-weight: 300;
	color: #000000;
	font-size: 24px;
	line-height: 1.3em;
	padding-bottom: 12px;
	margin: 0px;
}

.faq_left p span {
	font-weight: 400;
	color: #002f34;
}

.faq_btn_row {
	width: 100%;
}
.faq_btn_row a {
	max-width: 230px;
	display: block;
	margin: auto;
}

.faq_right {
	width: 384px;
	height: 384px;
	display: block;
	flex-shrink: 0;
	position: relative;
	position: relative;
	z-index: 2;
}

.faq_right strong {
	display: flex;
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	font-size: 32px;
	color: #0071bc;
	font-weight: 700;
	flex-direction: column;
	line-height: 1.2em;
	padding-bottom: 50px;
}

.faq_right strong small {
	display: block;
	line-height: 1.2em;
	font-weight: 700;
	font-size: 20px;
	text-transform: uppercase;
}

.faq_right span {
	position: relative;
	z-index: 2;
	display: block;
	width: 100%;
	height: 100%;
}

.faq_right span img {
	display: block;
	width: 90%;
	height: 90%;
	object-fit: cover;
	max-width: none;
}
ul {
	list-style-position: inside;
	padding: 0 !important;
}

@media screen and (max-width: 767px) {
	.faq_main {
		background-color: #e0f2fc;
		padding: 30px 0px;
	}
	.faq_inner {
		flex-wrap: wrap;
	}
	.faq_left {
		width: 100%;
		padding: 0px 0px 30px 0px;
	}

	.faq_right {
		width: 100%;
		height: auto;
		padding-bottom: 40px;
	}
	.faq_right span {
		height: auto;
	}

	.faq_inner:before {
		display: none;
		width: 100%;
		right: 0px;
	}
	.faq_right:before,
	.faq_right:after {
		display: none;
	}
	.faq_left p {
		font-size: 18px;
	}

	.faq_left h2 {
		font-size: 40px !important;
	}
}

@media screen and (max-width: 1023px) {
    .faq_right span img {
		height: 60%;
		width: 60%;
		margin-inline: auto;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	.faq_main {
		background-color: #e0f2fc;
		padding: 30px 0px;
	}
	.faq_inner {
		flex-wrap: wrap;
	}
	.faq_left {
		width: 100%;
		padding: 0px 0px 30px 0px;
	}

	.faq_right {
		width: 100%;
		height: auto;
		padding-bottom: 40px;
	}
	.faq_right span {
		height: auto;
	}

	.faq_inner:before {
		display: none;
		width: 100%;
		right: 0px;
	}
	.faq_right:before,
	.faq_right:after {
		display: none;
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
	.faq_right {
		width: 400px;
		height: 400px;
	}
}
