#main-wrapper {
  padding-top: 100px;
}

.header {
  text-align: center;
}

.main-heading {
  font-size: 60px;
  line-height: 65px;
  margin-bottom: 10px;
}

.secondary-heading {
  margin-bottom: 50px;
}

.secondary-text {
  display: inline-block;
  background: lightcyan;
  padding: 2px;
  border-radius: 10px;
}

.middle-section {
  background: lightcyan;
  padding: 2% 15%;
}

.middle-section-heading {
  font-size: 22px;
  font-weight: bold;
}

.sitemap {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  font-weight: bold;
  margin-bottom: 10px;
}

.packages-list {
  text-align: center;
  padding: 10px 100px;
}

.packages-heading {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}

.package-card {
  background: lightcyan;
  height: 525px;
  padding: 20px 30px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 75%, 0 100%);
}

.package-card.premium {
  background-color: #50e9e9;
}

.package-card-name {
  background-color: #50e9e9;
  border-radius: 35px;
  font-weight: bold;
  padding: 10px 0;
}

.package-card-name.premium{
  background-color: lightcyan;
}

.package-description {
  font-weight: bold;
  margin-top: 70px;
  margin-bottom: 70px;
}

.package-price {
  font-size: 36px;
  font-weight: bold;
}

.contact {
  text-align: center;
  font-size: 25px;
}