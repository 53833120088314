.jus_main{ width: 100%; padding: 80px 0px 130px; overflow: hidden; background: url(../../assets/img/hm_joinus_bg.png) no-repeat top center;     background-size: cover; }

.jus_heading{ width: 100%; max-width: 750px; margin: auto; padding-bottom: 50px; text-align: center; }

.jus_heading h2{ font-family: 'Montserrat', sans-serif; font-weight: 700; color: #0071bc; font-size: 60px; margin: 0px; line-height:1em; padding-bottom: 30px; text-transform: uppercase; }

.jus_heading h2 span{ color:#F7A5F9; font-style: italic; }

.jus_heading p{ font-weight:400; color: #0c0d11; font-size: 30px; line-height:1.3em; margin: 0px; }

.jus_content{ width: 100%; display: flex; justify-content: space-between; flex-direction: row-reverse; align-items: center; }

.jus_content_left{ width: 54%; padding-left: 10px; }
.jus_content_right{ width: 46%; flex-shrink: 0; }

.jus_content_left ul{ padding-left: 0px; }

.jus_content_left ul li{ list-style: disc outside; margin-left: 20px; padding-bottom: 20px; font-weight:500; color: #0c0d11; font-size: 28px; line-height:1.3em; }

.jus_content_left ul li::marker { color: #0071bc; }

.jus_content_left strong{ font-weight: 700; color: #0071bc; font-size: 30px; margin: 0px; line-height:1.3em; padding-bottom: 40px; display: block; }

.jus_content_img{ width: calc(100% + 200px); }

.jus_content_img img{ width: 100%; height: auto; max-width: none; display: block; }


@media screen and (max-width:767px) {
    .jus_main{ padding: 50px 0px; }
    .jus_heading{ padding-bottom: 30px; }
    .jus_heading h2{ font-size: 40px !important; padding-bottom: 15px; }
    .jus_heading p{ font-size: 16px; }
    .jus_content_left ul li{ font-size: 14px; padding-bottom: 15px; }
    .jus_content{ display: inherit; }
    .jus_content_img, .jus_content_right, .jus_content_left{ width: 100%; }
    .jus_content_right{ padding-bottom: 30px; }
    .jus_content_left ul{ padding-left: 15px; }
    .jus_content_left strong{ font-size: 18px; }
}

@media only screen and (min-width:768px) and (max-width:1023px) {
    .jus_main{ padding: 50px 0px; }
    .jus_heading{ padding-bottom: 30px; }
    .jus_heading h2{ font-size: 50px !important; padding-bottom: 15px; }
  
    .jus_content_left ul li{   padding-bottom: 15px; }
    .jus_content{ display: inherit; }
    .jus_content_img, .jus_content_right, .jus_content_left{ width: 100%; }
    .jus_content_right{ padding-bottom:50px; padding-top: 30px; }
    .jus_content_left ul{ padding-left: 15px; }
 


}















