body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /* background-color: #d5d5d5; */
  height: 100vh;
  /* max-width: 90%; */
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.leave {
  justify-content: center;
}

.user-self {
  color: green;
}

.user-them {
  color: red;
}

.panel {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;
  max-width: 300px;
  max-height: 400px;
  backdrop-filter: blur(4px);
  border-radius: 10px;
  overflow: auto;
}

.main {
  margin-top: 500px;
  text-align: center;
  padding: 1em;
}

.inner {
  padding: 10px;
  width: auto;
  height: auto;
}

.user-self {
  padding-top: 10px;
  color: green;
}

.user-them {
  padding-top: 10px;
  color: red;
}

.button {
  position: absolute;
  top: -10px;
  right: 0px;
  width: 4em;
  height: 4em;
  border: none;
  background: white;
  border-radius: 30px;
  transition: background 0.5s;
  z-index: 99999;
}

.X {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2em;
  height: 1.5px;
  background-color: blue;
  transform: translateX(-50%) rotate(45deg);
}

.Y {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2em;
  height: 1.5px;
  background-color: blue;
  transform: translateX(-50%) rotate(-45deg);
}

.close {
  position: absolute;
  display: flex;
  padding: 0.8rem 1.5rem;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%);
  top: -70%;
  left: 50%;
  width: 3em;
  height: 1.7em;
  font-size: 12px;
  background-color: rgb(19, 22, 24);
  color: blue;
  border: none;
  border-radius: 3px;
  pointer-events: none;
  opacity: 0;
}

.text {
  max-width: 160px;
  max-height: auto;
  border-radius: 10px;
  background-color: red;
  padding: 10px 10px 10px 10px;
  background-color: #d5d5d5;
  color: black;
  font-size: 0.9rem;
  font-weight: bold;
  word-wrap: break-word;
}
.send-message-input {
  padding: 10px 40px 10px 10px !important;
  position: absolute !important;
  bottom: 20px !important;
}

.message-participant {
  padding: 10px !important;
}

#root {
  width: 100%;
}

@media (max-width: 393px) {
  .participant {
    padding: 0.3rem !important;
    font-size: 0.7rem !important;
    height: fit-content;
  }
  .message-participant {
    padding: 0px !important;
  }
  .joining-timer {
    padding: 0.3rem !important;
  }
  .users-count {
    padding: 0.3rem !important;
  }
  .messages-title {
    padding: 0.3rem !important;
    font-size: 0.7rem !important;
    height: fit-content;
  }
  .panel {
    max-height: 400px !important;
  }
  .main-grid-wrapper {
    padding: 0rem !important;
    margin-top: 0px !important;
  }
  .send-message-input {
    width: 140px !important;
  }
}
@media (max-width: 320px) {
  .panel {
    max-height: 250px !important;
  }
  .main-grid-wrapper {
    padding: 0rem !important;
    margin-top: 0px !important;
  }
  .send-message-input {
    width: 140px !important;
  }
  body {
    margin: 0 0 0 0;
  }
}
@media (max-width: 600px) {
  .main-grid-wrapper {
    padding: 0rem !important;
    margin-top: 0px !important;
  }
  .controls {
    width: 165px !important;
    margin-top: -100px !important;
  }
  .button {
    width: 2em;
    height: 2em;
  }
  .X {
    width: 1em;
  }
  .Y {
    width: 1em;
  }
  .send-message-input {
    padding: 10px 40px 10px 10px !important;
    position: absolute !important;
    bottom: 20px !important;
  }
  .send-message-input {
    width: 160px !important;
    padding: 0px 4px 0px 4px !important;
  }
}