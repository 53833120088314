.mainDiv {
	width: 100%;
	font-family: "Montserrat", sans-serif;
}

.mainDiv h2 {
	font-weight: bold;
	font-size: 30px;
	margin-bottom: 23px;
}

.hiw_list_parent {
	width: 100%;
	padding: 0px 0px 60px 0px;
}

.box_cell {
	box-sizing: border-box;
	flex-shrink: 0;
	width: 33.33%;
	padding: 60px 15px;
}

.connectDentist_headlines {
	background-color: #e0f2fc;
	padding: 36px 0px;
	text-align: center;
	position: relative;
}

.connectDentist_headlines h2 {
	color: #002f34;
	font-size: 28px;
	line-height: 1em;
	margin: 0px;
	padding-bottom: 10px;
	font-weight: 700;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif;
}

.connectDentist_headlines:after {
	content: "";
	display: block;
	position: absolute;
	left: 50%;
	top: 100%;
	margin-left: -24px;
	z-index: 2;
	width: 0;
	height: 0;
	border-width: 48px 49px 0 49px;
	border-color: #e0f2fc transparent transparent transparent;
	border-style: solid;
}

.connectDentist_headlines h2 span {
	background: #0171bd;
	color: #fff;
	border-radius: 24px;
	padding: 3px 10px;
}

.connectDentist_headlines p {
	color: #002f34;
	font-size: 22px;
	margin: 0px;
	font-weight: 700;
	font-family: "Montserrat", sans-serif;
	font-weight: 300;
	line-height: 1.3em;
}

.underline_pink {
	border: 0px none;
	font-style: italic;
	font-weight: 500;
	color: #f7a5f9;
	background: none;
	transition: background-color 300ms ease, transform 300ms ease,
		color 300ms ease;
	text-decoration: underline;
}

.underline_pink:hover {
	text-decoration: none;
}

.hiw_list_parent h2 {
	text-align: center;
	font-size: 36px;
	color: #000;
	font-weight: 800;
}

.hiw_btn {
	width: 100%;
	text-align: center;
	padding-top: 20px;
	display: flex;
	flex-wrap: wrap;
	gap: 15px;
	align-items: center;
	justify-content: center;
}

@media screen and (max-width: 767px) {
	.connectDentist_headlines h2 {
		line-height: 1.4em;
	}
	.connectDentist_headlines h2 span {
		padding-top: 3px;
		padding-bottom: 3px;
	}
	.box_cell {
		width: 100%;
		padding: 20px 0px;
	}
	.connectDentist:before,
	.connectDentist:after {
		display: none;
	}
}

.hsat_devider {
	height: 2px;
	background: #000;
	margin: 14px auto;
	max-width: 56px;
	color: #000;
}

.hsa_top_header p {
	text-align: center;
	color: #222;
	font-size: 18px;
  margin-block: 20px;
}

.hsa_top_header span {
	color: #0071bc;
	font-weight: 600;
}
